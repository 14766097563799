import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"enneagram-test"} />
		<Helmet>
			<title>
				Profilozó Személyiségteszt | Sziklay Bence
			</title>
			<meta name={"description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!\n"} />
			<meta property={"og:title"} content={"Profilozó Személyiségteszt | Sziklay Bence"} />
			<meta property={"og:description"} content={"Értsd meg önmagad egy Enneagram alapú személyiségteszttel! Lépj az önfelfedezés útjára most!\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/creativeOG.webp?v=2024-08-06T08:29:54.434Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6602d5961d712a0023322e52/images/small_logox.png?v=2024-04-10T11:54:12.356Z"} />
		</Helmet>
		<Components.NavBar>
			<Override slot="linkContact" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
		</Components.NavBar>
		<Section background="--color-light">
			<Box
				padding="16px 16px 16px 16px"
				max-width="600px"
				margin="0px auto 0px auto"
				border-radius="15px"
				background="#ffffff"
				box-shadow="--m"
				font="--lead"
				color="--primary"
				text-align="center"
			>
				<Text margin="0px 0px 0px 0px">
					Értékelj minden állítást egy 1-től 5-ig terjedő skálán, ahol az 1 azt jelenti, hogy az állítás egyáltalán nem igaz rád, az 5 pedig azt, hogy teljes mértékben igaz rád.{"\n\n\n\n\n\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.EnneaWrapper
			padding="24px 0px 0px 0px"
			background="--color-light"
			lg-position="relative"
			lg-z-index="0"
			min-height="89vh"
		>
			<Override slot="ButtonsWrapper" />
			<Override slot="Next Btn" />
			<Override slot="Prev Btn" />
			<Override slot="ProgressBar" margin="0px 0px 30px 0px" z-index="5">
				<Override
					slot="Wrapper"
					border-radius="6px"
					background="rgba(72, 124, 207, 0.3)"
					justify-items="center"
					align-content="center"
					align-items="center"
					justify-content="center"
					height="28px"
				/>
				<Override slot="PGLabel" font="--lead" color="--light" text-shadow="0 0 4px --color-primary" />
			</Override>
			<Override slot="ResultsBtnWrapper" />
			<Override slot="ResultsBtn" />
			<Override slot="OuterWrapper" />
			<Override slot="LinkText" />
			<Override
				slot="loader-spinner"
				position="absolute"
				right={0}
				left={0}
				top="50vh"
			/>
			<Override slot="Loading Label" text-align="center" color="--secondary" />
		</Components.EnneaWrapper>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b3caaa009abe001f026b4e"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});